@import '../../variables.scss';

.menu-column {
  h1 {
    padding: 1rem;
  }
}

.top-left-image {
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 15vw;
  }
}

.left-image {
  img {
    position: absolute;
    top: 40vh;
    left: 0;
    width: 20vw;
  }
}

.bottom-image {
  img {
    position: absolute;
    bottom: 0px;
    right: 10vw;
    height: 15vh;
  }
}

.right-image {
  img {
    position: absolute;
    right: 0px;
    top: 10vh;
    max-width: 10vw;
    height: 60vh;
  }
}

.homepage-container {
  @media (max-width: $maxSm) {
    .center-vertically {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    &.full-height,
    .full-height {
      height: auto;
    }

    .menu-column {
      h1 {
        padding: .5rem 0px;
      }
    }
  }
}

@media (max-width: $maxSm) {
  .bottom-image {
    img {
      position: unset;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
