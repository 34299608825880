@import './variables.scss';

body {
  color: $fontColorMain;
  background-color: $fontColorWhite;
}

a {
  color: $fontColorMain;

  h1 {
    padding: 0px 10px;
  }
}

a:hover {
  color: $fontColorMain;
  text-decoration: none;
}

div.nav-item {
  padding:  0 20px;
}

a:hover.nav-item,
.nav-item-active {
  background-image: url(./assets/menu-bg-pink.svg);
  background-repeat: no-repeat;
  display: inline-block;
  background-position: center;
  h1 {
    font-weight: 700;
  }
}

h1 {
  font-family: amatic-sc, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 3rem;
}

.full-height {
  height: 100vh;
}

.center-vertically {
  margin-top: auto;
  margin-bottom: auto;
}

.right-align-text {
  text-align: right;
}
